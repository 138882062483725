import {
  ORANGE,
  tableSX,
} from '../../logic/u'
import React, { useState, useEffect } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { getConfig } from '../../config'

const config = getConfig() as any

export function DisclosuresTab() {

  const documentRows = [
    {
      id: 1,
      type: 'Use and risk',
      link: '/alpaca_docs/UseAndRisk.pdf'
    },
    {
      id: 2,
      type: 'Terms and conditions',
      link: '/alpaca_docs/TermsAndConditions.pdf'
    },
    {
      id: 3,
      type: 'Privacy notice',
      link: '/alpaca_docs/PrivacyNotice.pdf'
    },
    {
      id: 4,
      type: 'PFOF',
      link: '/alpaca_docs/PFOF.pdf'
    },
    {
      id: 5,
      type: 'Margin',
      link: '/alpaca_docs/MarginDiscStmt.pdf'
    },
    {
      id: 6,
      type: 'Extended hours trading risk',
      link: '/alpaca_docs/ExtHrsRisk.pdf'
    },
    {
      id: 7,
      type: 'Business continuity plan',
      link: '/alpaca_docs/BCPSummary.pdf'
    },
    {
      id: 8,
      type: 'Customer relationship summary',
      link: '/alpaca_docs/FormCRS.pdf'
    }
  ]

  return (
    <div className='tab-contents has-subtab'>
      <div className='bold header-text-size margin-bottom-10'>Introduction</div>
      <div className='orange-text'>
        <p>Tickerbot, LLC ("Tickerbot") develops software designed to enhance users' trading experience in the stock market.</p>

        <p>Tickerbot offers its users access to brokerage services through a technology integration partnership with Alpaca Securities, LLC ("Alpaca"). Alpaca is a broker-dealer registered with the SEC and a member of FINRA, which means all Tickerbot users benefit from such protections and regulations.</p>

        <p>Users' accounts, orders, positions, and transactions are facilitated by Alpaca. Please note that Alpaca is not responsible for the technology and services provided by Tickerbot, but rather for the brokerage aspects, such as account management and trade executions.</p>
        
        <p>Neither Tickerbot nor Alpaca is an investment advisor. This communication is not an offer or solicitation to buy or sell securities or open a brokerage account in any jurisdiction where Alpaca is not registered (Alpaca is registered only in the United States). Users agree to hold Alpaca and its affiliates harmless for any loss or damage arising from the use of Tickerbot's technology and services. For more information, see Alpaca's disclosures at: <a style={{color: ORANGE}} href="https://alpaca.markets/disclosures">Alpaca Disclosures</a>.</p>
      </div>
      <div className='bold header-text-size margin-top-40 margin-bottom-10'>Brokerage partner (Alpaca) disclosures</div>
      <div className='table-container'>
        <DataGridPro
          rows={documentRows}
          columns={[
            { 
              field: 'type', 
              headerName: 'disclosure', 
              flex: 3,
              type: 'string',
              sortable: false,
            },
            { 
              field: 'link', 
              headerName: 'link', 
              flex: 1,
              sortable: false,
              headerAlign: 'right', 
              align: 'right',
              type: 'string',
              renderCell: (params) => (
                <div 
                  className='pointer hover-underline'
                  onClick={() => window.open(`${params.row.link}`, '_blank')}
                  >
                  Download PDF
                </div>
              )
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          sx={tableSX}
          localeText={{
            footerTotalRows: 'Total disclosures:',
          }}
        />
      </div>

    </div>
  )
}