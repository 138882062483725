import './RightPane.scss'
import { useAtom } from 'jotai'
import React, { useState, useEffect } from 'react';
import {
  openModalAtom,
  transfersAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { formatDateForTable, formatNumberForTable, valueForTable, roundToSigFigs } from '../../logic/u'
import { TransferDetailsModal } from '../modals/TransferDetailsModal'
import { TransferDropdown } from '../dropdowns/TransferDropdown'


export const TransfersPane = () => {
  const [ transfers ] = useAtom(transfersAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedTransferCategory, setSelectedTransferCategory] = useState<string>('recent')

  if (!uld) return null

  const incomingTransfers: any[] = []
  const outgoingTransfers: any[] = []

  transfers?.forEach(transfer => {
    if (transfer.direction === 'INCOMING') {
      incomingTransfers.push(transfer)
    }
    if (transfer.direction === 'OUTGOING') {
      outgoingTransfers.push(transfer)
    }
  })

  let transfersToShow: any[] | null = []
  if (selectedTransferCategory === 'recent') {
    transfersToShow = transfers
  }
  if (selectedTransferCategory === 'incoming') {
    transfersToShow = incomingTransfers
  }
  if (selectedTransferCategory === 'outgoing') {
    transfersToShow = outgoingTransfers
  }

  const rows: JSX.Element[] = []
  transfersToShow?.forEach((transfer, index) => {
    const div = (
      <div
      className={transfer.status === 'CANCELED' ? 'entry cancelled' : 'entry'}
      onClick={() => setOpenModal(<TransferDetailsModal transferObj={transfer} />)}
      onContextMenu={(event) => {
        event.preventDefault()
        const width = 220
        const clickX = event.clientX - (width + 5)
        const clickY = event.clientY - 10
        setOpenDropdown(
          <TransferDropdown
            transferObj={transfer}              
            left={clickX}
            top={clickY}
            width={width}                      
            onClose={() => setOpenDropdown(null)}
          />
        )
      }}
      >
        <div className={'column'}>
          <div className='row space-between'>
            <div>{transfer.direction}</div>
            <div>{valueForTable('amount', transfer.amount)}</div>
          </div>
          <div className='row space-between font-size-11'>
            <div>{transfer.status}</div>
            <div>{formatDateForTable(transfer.updated_at)}</div>
          </div>
        </div>
      </div>
    )
    rows.push(div)
  })

  const SubHeader = () => {
    return (
      <div className='subheader'>
        <div
          className={selectedTransferCategory === 'recent' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedTransferCategory('recent')}}
        >
          Recent
        </div>
        <div
          className={selectedTransferCategory === 'incoming' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedTransferCategory('incoming')}}
        >
          Incoming
        </div>
        <div
          className={selectedTransferCategory === 'outgoing' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedTransferCategory('outgoing')}}
        >
          Outgoing
        </div>
      </div>
    )
  }
  if (!transfers) {
    return (
      <>
        <SubHeader />
        <div className='sect-body has-subheader'>
          <div
            className='margin-top-10 margin-left-10 orange-text font-size-11'
          >
            Loading transfers...
          </div>
        </div>
      </>
    )
  }
  if (rows.length) {
    return (
      <>
        <SubHeader />
        <div className='sect-body has-subheader'>
          {openDropdown}
          {rows}
        </div>
      </>
    )
  }
  return (
    <>
      <SubHeader />
      <div className='sect-body has-subheader'>
        <div
          style={{marginTop: 8, marginLeft: 10}}
          className='orange-text font-size-11'
        >
          No transfers.
        </div>
      </div>
    </>
  )
}
