import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { currentUser } from '../../firebase'
import { Modal, showModal, Checkbox, Switch } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import { openModalAtom } from '../../types/global_types'
import { updateLiveData } from '../../logic/u'
const config = getConfig() as any

export const CancelAllOpenOrdersModal = () => {
  // General helpers
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  return (
    <Modal
      title='Cancel all open orders'
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to cancel all your open orders?
          </div>
        </div>
      ]}
      yesButtonText={'Submit'}
      isDanger
      isButtonDisabled={buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const url = `${config.api_root_url}deleteOrders?create_ee_log=true`
          await axios.delete(url, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}