/*
AppTab handler
*/

import './AppTabs.scss';

import React, { useRef, useState } from 'react'
import { useAtom } from 'jotai'
import {
  appTabAtom,
  appTabType,
  openModalAtom,
  loggedInUserAtom,
  strategiesWithUnsavedChangesAtom,
} from '../../types/global_types'
import { Icon } from '../reusable/Icon'
import { Modal } from '../reusable';
import { AccountDropdown } from '../dropdowns/AccountDropdown';

export function AppTabs() {
  const [ appTab, setAppTab ] = useAtom(appTabAtom)
  const [ dropdownMenu, setDropdownMenu ] = useState<any>()
  const [, setOpenModal] = useAtom(openModalAtom)
  const [functionsWithUnsavedChanges, setFunctionsWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const [user] = useAtom(loggedInUserAtom)
  const accountIconRef = useRef<HTMLDivElement>(null)

  if (!user) return null

  interface Tab {
    display: string
    value: appTabType
    icon: string
    size: number
    marginBottom: number
    marginLeft?: number
    isInTopSection: boolean
    ref?: React.RefObject<HTMLDivElement>
  }

  const TABS: Tab[] = [
    {
      display: 'Home',
      value: 'home',
      icon: 'house',
      size: 21,
      marginBottom: 12,
      isInTopSection: true
    },
    {
      display: 'Watchlists',
      value: 'watchlists',
      icon: 'square-list',
      size: 18,
      marginBottom: 15,
      isInTopSection: true
    },
    {
      display: 'Strategies',
      value: 'strategies',
      // icon: 'function',
      // icon: 'square-code',
      icon: 'square-bolt',
      size: 18,
      marginBottom: 17,
      isInTopSection: true
    },
    {
      display: 'Backtests',
      value: 'backtests',
      icon: 'flask-vial',
      size: 19,
      marginBottom: 14,
      marginLeft: 2,
      isInTopSection: true
    },
    {
      display: 'Portfolio',
      value: 'portfolio',
      icon: 'chart-pie',
      size: 20,
      marginBottom: 11,
      isInTopSection: true
    },
    {
      display: 'Activity',
      value: 'activity',
      icon: 'wave-pulse',
      size: 18,
      marginBottom: 10,
      isInTopSection: true
    },    
    {
      display: 'Admin',
      value: 'admin',
      icon: 'user-shield',
      size: 19,
      marginBottom: 13,
      marginLeft: 2,
      isInTopSection: false
    },
    {
      display: 'Account',
      value: 'account',
      icon: 'circle-user',
      size: 19,
      marginBottom: 10,
      isInTopSection: false,
      ref: accountIconRef
    },
  ]

  // If user's email address ends with @tickerbot.io, show the admin tab
  if (!user.email.endsWith('@tickerbot.io')) {
    const adminTab = TABS.find(t => t.display === 'admin')
    if (adminTab) {
      const index = TABS.indexOf(adminTab)
      TABS.splice(index, 1)
    }
  }

  const renderTab = (tab: Tab) => {
    let className = 'tab-icon pointer'
    let set: 'regular' | 'sharp-solid' = 'regular'
    if (appTab === tab.value) {
      className += ' selected'
      set = 'sharp-solid'
    }
    return (
      <div
        title={tab.display}
        key={tab.value}
        className={className}
        style={{marginBottom: tab.marginBottom, marginLeft: tab.marginLeft}}
        ref={tab.ref}
        onClick={(e) => {

          // If we are currently editing a function, warn user about saving
          if (appTab === 'strategies') {
            if (Object.keys(functionsWithUnsavedChanges).length) {
              setOpenModal(
                <Modal
                  title={'Unsaved changes'}
                  contents={[
                    <div className='column width-full font-size-13 orange-text'>
                      <div className='row'>
                        You have unsaved function editor changes. Navigating away will cause them to be discarded.
                        <br />
                        <br />
                        Do you want to proceed?
                      </div>
                    </div>
                  ]}
                  yesButtonText={'Yes, discard'}
                  isDanger
                  onYes={ async () => {
                    setAppTab(tab.value)
                    setFunctionsWithUnsavedChanges({})
                  }}
                />
              )
              return
            }
          }

          if (tab.value === 'account') {
            const rect = accountIconRef.current?.getBoundingClientRect();
            const clickX = rect ? rect?.left : 0
            const clickY = rect ? rect?.top - rect?.height - 125: 0
            setDropdownMenu(<AccountDropdown
              left={clickX}
              top={clickY}
              onClose={() => setDropdownMenu(null)}
            />)
            return
          }
          setAppTab(tab.value)}
        }
      >
        <Icon
          hoverText={tab.display}
          icon={tab.icon}
          set={set}
          size={tab.size}
          onClick={() => {
            // for the hover effect
          }}
        />

      </div>
    )
  }

  const topTabs = TABS.filter((tab) => tab.isInTopSection).map(renderTab)
  const bottomTabs = TABS.filter((tab) => !tab.isInTopSection).map(renderTab)

  return (
    <div className='app-tabs'>
      {dropdownMenu}
      <div className='column align-center width-full margin-top-10'>
        {topTabs}
      </div>
      <div className='column align-center width-full'>
        {bottomTabs}
      </div>
    </div>
  )
}
