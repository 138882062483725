import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  openModalAtom,
  userLiveDocAtom,
  loggedInUserAtom,
  consoleOutputAtom,
  selectedCodeEditorSlotAtom,
  strategiesWithUnsavedChangesAtom,
  cmdSAtom, cmdTAtom, cmdOAtom
} from '../../types/global_types';
import { DropdownMenu, DropdownSelect, Icon, Switch } from '../reusable';
import { CodeEditor } from './CodeEditor';
import { Strategy, User } from '../../types/user_types';
import { db } from '../../firebase';
import { StrategyDropdown } from '../dropdowns/StrategyDropdown';
import { createEELog, formatDateForTable, timeAgo } from '../../logic/u';
import { CreateBacktestModal } from '../modals/CreateBacktestModal';

interface CodeEditorWrapperProps {
  codeEditorSlot: number
  strategy?: Strategy
}

export const CodeEditorWrapper = (props: CodeEditorWrapperProps) => {
  const {codeEditorSlot, strategy} = props

  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)
  const [ dropdown, setDropdown ] = useState<any>()
  const [, setOpenModal] = useAtom(openModalAtom)

  const [cmdS] = useAtom(cmdSAtom)
  const [cmdT] = useAtom(cmdTAtom)
  const [cmdO] = useAtom(cmdOAtom)

  const page_load_start_ts = new Date().getTime()

  // Save on cmd + s, see keyboard listener in app
  useEffect(() => {
    if (!cmdS) return
    if (!strategy || !user) return
    save_strategy_code(strategy, user, strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges)
  }, [cmdS])

  let codeEditorWrapperClass = 'code-editor-wrapper'
  if (user?.codeEditorConfig === '2-one-left-one-right') {
    codeEditorWrapperClass += ' two-left-right'
  }
  if (!strategy) {
    codeEditorWrapperClass += ' empty'
  }

  let emptyHeaderClass = 'tab-header empty'
  let emptyBoxClass = 'empty-box'
  let emptyText = 'Click here'
  if (selectedCodeEditorSlot === codeEditorSlot) {
    emptyHeaderClass += ' selected'
    emptyBoxClass += ' selected'
    emptyText = 'Choose a strategy...'
  }

  if (!user || !uld) return null

  if (!strategy) return (
    <div className={codeEditorWrapperClass}>
      <div className={emptyHeaderClass}>
      </div>
      <div
        className={emptyBoxClass}
        onClick={() => {
          setSelectedCodeEditorSlot(selectedCodeEditorSlot ? null : codeEditorSlot)
        }}
      >
        {emptyText}
      </div>
    </div>
  )

  return (
    <div className={codeEditorWrapperClass}>
      {dropdown}
      <div className='tab-header'>
        <div className='row align-center'>
          <div className={user.codeEditorConfig !== '1' ? 'strategy-tab-name smaller' : 'strategy-tab-name'}>
            {strategy?.name}
          </div>
          {strategiesWithUnsavedChanges[strategy.id] ? <div
            className='strategies-tab-header-btn'
            style={{marginLeft: 12}}
            onClick={async () => {
              save_strategy_code(strategy, user, strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges)
            }}
          >
            <Icon
              icon='floppy-disk'
              className='strategies-tab-header-icon'
              hoverText='Run strategy once'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>
              Save
            </div>
          </div> : null}
        </div>

        {/* Right */}
        <div className='row'>
          <div
            className={'strategies-tab-header-btn'}
            onClick={() => {
              setOpenModal(<CreateBacktestModal strategyId={strategy.id} />)
            }}
          >
            <Icon
              icon='circle-plus'
              className='strategies-tab-header-icon'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>Backtest</div>
          </div>
          <Icon
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 5.5}}
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const width= 175
                const left = rect.left - width + 10
                const top = rect.bottom
                setDropdown(<StrategyDropdown
                  left={left}
                  top={top}
                  strategyObj={strategy}
                  width={width}
                  onClose={() => setDropdown(null)}
                />)
              }
            }}
          />
        </div>
      </div>
      <CodeEditor strategy={strategy} />
    </div>
  )
}

const save_strategy_code = async(strategy: Strategy, user: User, strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges) => {

  const unsavedCode = strategiesWithUnsavedChanges[strategy.id]

  // Extract function names
  const function_metadata = extract_function_names(unsavedCode)

  // Identify any charts not included in the code
  await add_charts_not_included(unsavedCode, user)

  const updatedStrategy: Strategy = {
    ...strategy,
    code: unsavedCode,
    updatedAt: new Date(),
    function_metadata,
  }

  await db.collection('users')
    .doc(user.uid)
    .collection('strategies')
    .doc(updatedStrategy.id)
    .set(updatedStrategy)

  let newState = {...strategiesWithUnsavedChanges}
  delete newState[strategy.id]
  setStrategiesWithUnsavedChanges(newState || {})
}

// Pulls function metadata out of user code
const extract_function_names = (code: string) => {
  // strategy.on_interval(, strategy.on_trigger()
  const regex = /strategy\.(on_interval|on_trigger)\s*\(([^)]*)\)/g;
  const results: any = [];
  let match;
  while ((match = regex.exec(code)) !== null) {
      const type = match[1];
      const argsString = match[2];

      const splitArguments = (str) => {
        const args: string[] = [];
        let currentArg = '';
        let parentheses = 0;
        let quotes = null;
        for (let i = 0; i < str.length; i++) {
          const char = str[i];
          if (quotes) {
            if (char === quotes && str[i - 1] !== '\\') {
              quotes = null;
            }
            currentArg += char;
            continue;
          } else {
            if (char === '"' || char === "'" || char === '`') {
              quotes = char;
              currentArg += char;
              continue;
            }
          }

          if (char === '(') {
            parentheses++;
          } else if (char === ')') {
            parentheses--;
          }

          if (char === ',' && parentheses === 0) {
            args.push(currentArg.trim());
            currentArg = '';
          } else {
            currentArg += char;
          }
        }

        if (currentArg.trim() !== '') {
            args.push(currentArg.trim());
        }
        return args;
      }

      const args = splitArguments(argsString);

      const params: any = {};
      const name = args[0]
      if (type === 'on_interval') {
        params.interval = args[1];
      }

      results.push({ type, name, params });
  }
  return results
}

// TODO: error handling, name, further testing
const add_charts_not_included = async (code: string, user: User) => {
  const regex = /tb\.ticker\s*\(\s*(['"`])([^'"`]+)\1\s*(?:,[^)]*)?\)/g;

  // Initialize an array to hold the ticker arguments
  const tickers: string[] = [];
  let match;
  while ((match = regex.exec(code)) !== null) {
    tickers.push(match[2]);
  }

  // Identify which of these are not presently in user.charts
  const charts_on_user = Object.keys(user.charts)
  const tickers_unaccounted_for = tickers.filter(ticker => !charts_on_user.includes(ticker))

  // Add unaccounted for tickers to user's charts in firebase
  if (tickers_unaccounted_for.length === 0) return

  const userRef = db.collection('users').doc(user.uid)
  const userDoc = await userRef.get()
  const userData = userDoc.data()
  if (!userData) throw new Error('INTERNAL ERROR: User not found')

  const updatedCharts = {...userData.charts}
  tickers_unaccounted_for.forEach(ticker => {
    updatedCharts[ticker] = {
      symbol: ticker,
      display: 'ADD DISPLAY NAME',
      indicators: []
    }
  })

  await userRef.update({ charts: updatedCharts })
}