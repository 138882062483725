import './Strategies.scss'
import { useAtom } from 'jotai';
import { loggedInUserAtom, userLiveDocAtom, openModalAtom, selectedCodeEditorSlotAtom, strategiesWithUnsavedChangesAtom, strategiesAtom, deploymentsAtom } from '../../types/global_types';
import { Icon, Modal } from '../reusable'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../firebase';
import { GREEN_PARTIAL, RED_PARTIAL, formatDateForTable, timeAgo } from '../../logic/u';
import { Strategy } from '../../types/user_types';
import { CreateStrategyModal } from '../modals/CreateStrategyModal';
import { ORANGE } from '../../logic/colors';
import { StrategyDropdown } from '../dropdowns/StrategyDropdown';

export const StrategiesTree = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom);
  const [userLiveDoc] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [deployments, setDeployments] = useAtom(deploymentsAtom)
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)

  const [validDrop, setValidDrop] = useState<boolean>(false)

  const onDragUpdate = (update) => {
    const { source, destination } = update
    console.log(update)
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let strategiesClone = Array.from(strategies) || []
    const [reorderedItem] = strategiesClone.splice(source.index, 1) // remove dragged item
    strategiesClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setStrategies(strategiesClone) // local state

    // Update index for each strategy
    const strategiesRef = db
      .collection('users')
      .doc(user?.uid)
      .collection('strategies');
  
    const batch = db.batch(); // Firestore batch for atomic updates
    
    strategiesClone.forEach((strategy, index) => {
      const docRef = strategiesRef.doc(strategy.id);
      batch.update(docRef, { index }); // Update each document with the index from the array
    });
  
    await batch.commit(); // Commit all updates in a single batch
  }

  const flagUnsavedChangesBeforeChangingStrategies = (strategyId: string, changeSelection: boolean) => {
    const changeSelectedFunction = () => {
      setStrategiesWithUnsavedChanges({})
      if (selectedCodeEditorSlot === 1) {
        db.collection('users').doc(user?.uid).update({ codeEditorSlot1: strategyId });
        return
      }
      if (selectedCodeEditorSlot === 2) {
        db.collection('users').doc(user?.uid).update({ codeEditorSlot2: strategyId });
        return
      }
    
      // change the first slot even if it's not selected
      db.collection('users').doc(user?.uid).update({ codeEditorSlot1: strategyId });
    }
    if (Object.keys(strategiesWithUnsavedChanges).length) {
      setOpenModal(
        <Modal
          title={'Unsaved changes'}
          contents={[
            <div className='column width-full font-size-13 orange-text'>
              <div className='row'>
                You have unsaved editor changes. Navigating away will cause them to be discarded.
                <br />
                <br />
                Do you want to proceed?
              </div>
            </div>
          ]}
          yesButtonText={'Yes, discard'}
          isDanger
          onYes={ async () => {
            changeSelectedFunction()
          }}
        />
      )
      return
    }
    if (changeSelection) {
      changeSelectedFunction()
    }
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!userLiveDoc || !userLiveDoc.liveData) return null

  const liveCode = deployments.find(d => d.id === user?.liveDeploymentId)?.liveCode || {}

  return (
    <div className='strategies-tree'>
      {openDropdown}
      <div className='header'>
        <div>
          Strategies
        </div>
        <div>
          <Icon
            icon='plus'
            set='sharp-solid'
            size={12}
            style={{marginTop: -.5}}
            onClick={() => {
              setOpenModal(<CreateStrategyModal />)
            }}
          />
        </div>
      </div>

      {!strategies.length ? <div 
        style={{marginTop: 8, marginLeft: 10, fontSize: 11.5, color: ORANGE}}
      >
        No strategies.
      </div> : null}

      <div className='strategies-list-container'>
        <DragDropContext
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
          key={'modal-content'}
        >
          <div className='droppable-container'>
            <Droppable droppableId={'1'} type='bucket'>
              {(provided, snapshot) => (
                <div
                  className='draggable-container'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {strategies.map((strategyObj: Strategy, index) => {

                    const includedInLiveCode = Object.keys(liveCode).includes(strategyObj.id)
                    const outOfSync = includedInLiveCode && liveCode[strategyObj.id] !== strategies.find(s => s.id)?.code

                    let parentClass = 'entry smaller'
                    const visibleEditors = user.codeEditorConfig === '2-one-left-one-right' ? [user.codeEditorSlot1, user.codeEditorSlot2] : [user.codeEditorSlot1]
                    if (visibleEditors.includes(strategyObj.id)) {
                      parentClass += ' selected'
                    }
                  
                    return (
                      <Draggable
                        key={strategyObj.id}
                        draggableId={strategyObj.id}
                        index={index}
                      >
                        {(provided, snapshot) => {

                          return (
                            <div
                              className={parentClass}
                              title={strategyObj.name}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                              }}
                              onClick={async (e) => {
                                flagUnsavedChangesBeforeChangingStrategies(strategyObj.id, true)
                              }}
                              onContextMenu={(event) => {
                                event.preventDefault()
                                const width = 220
                                const clickX = event.clientX + 5
                                const clickY = event.clientY - 10
                                setOpenDropdown(
                                  <StrategyDropdown
                                    strategyObj={strategyObj}
                                    flagUnsavedChangesBeforeChangingStrategies={flagUnsavedChangesBeforeChangingStrategies}
                                    left={clickX}
                                    top={clickY}
                                    width={width}                      
                                    onClose={() => setOpenDropdown(null)}
                                    fromStrategiesList={true}
                                  />
                                )
                              }}
                            >
                              <div className='column'>
                                <div className={'top-row'}>
                                  <div className='row'>
                                    <div className='icon-container'>
                                      <Icon
                                        icon='grip-dots-vertical'
                                        size={5.5}
                                        className='grip-icon'
                                      />
                                    </div>
                                    <Icon
                                      icon={'square-bolt'}
                                      set='sharp-solid'
                                      size={12}
                                      className={'key-icon'}
                                      style={{marginTop: .5, marginRight: 5, marginLeft: 0}}
                                    />
                                    <div className='function-title'>
                                      {strategyObj.name}
                                    </div>
                                  </div>
                                  <div className='row'>
                                    {outOfSync && <Icon 
                                      hoverText='Deployed code is out of sync from current code.'
                                      className='key-icon'
                                      icon='rotate-exclamation'
                                      size={14}
                                      style={{marginRight: 5, marginTop: 1}}
                                      // onClick={() => {
                                      //   // for effect
                                      // }}
                                    />}
                                    {includedInLiveCode && <Icon 
                                      hoverText='Included in latest deployment.'
                                      icon='square-bolt'
                                      size={12}
                                      className='key-icon'
                                      style={{marginRight: 0, marginTop: 1}}
                                      // onClick={() => {
                                      //   // for effect
                                      // }}
                                    />}
                                  </div>
                                </div>                                
                              </div>
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}