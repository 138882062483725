

import { useAtom } from 'jotai'
import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Checkbox, IntInput, Modal } from '../reusable'
import { updateLiveData } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { loggedInUserAtom, openModalAtom, selectedChartAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { Variable } from '../../types/user_types'
import { DeleteVariableModal } from './DeleteVariableModal'
import { db } from '../../firebase'

export interface CreateVariableModalProps {
  variable?: Variable
}

export const CreateVariableModal = (props: CreateVariableModalProps) => {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // Form fields
  const variable = props.variable
  const [name, setName] = useState<string>(variable?.symbol.replace(/\s/g, '_') || '')
  const [changeValue, setChangeValue] = useState<boolean>(false)
  const [type, setType] = useState<Variable['type']>(variable?.type || 'number')
  const [numberValue, setNumberValue] = useState<number>(variable?.type === 'number' ? variable?.value : 0)
  const [booleanValue, setBooleanValue] = useState<boolean>(variable?.type === 'boolean' ? variable?.value : false)
  const [yesButtonText, setYesButtonText] = useState<string>(variable ? 'Update' : 'Add')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if variable name is unique
    const variables = user?.variables
    for (const key in variables) {
      const innerObj = variables[key];
      if (innerObj && innerObj['symbol'] === name && variable?.symbol !== name) {
        errorMessage = 'Variable name is being used'
      }
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Variable name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Variable name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name)
  }, [name, changeValue, type, booleanValue, numberValue, errorMessage]);

  if (!user) return null

  const content = (
    <div className='column width-full'>
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_');
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {variable && <div className='row margin-top-20'>
        <div style={{marginRight: 6, paddingTop: 1.5}}>
          <Checkbox
            // className='margin-right-10'
            value={changeValue}
            onChange={() => setChangeValue(!changeValue)}
            checkMarginTop={-2}
          />
        </div>
        <div className='white-text font-size-13 bold'>
          Change value
        </div>
      </div>}

      {(variable && changeValue || !variable) && <DropdownSelect
        label={'Type'}
        options={[
          {value: 'number', display: 'Number'},
          {value: 'boolean', display: 'Boolean'},
        ]}
        className={'margin-top-20'}
        width={362}
        value={type}
        onChange={(val) => {
          setType(val)
        }}
      />}

      {/* Value input - number */}
      {(variable && changeValue && type === 'number' || (!variable && type === 'number')) && <IntInput
        label={'Value'}
        className={'margin-top-20'}
        style={{width: '100%'}}
        value={numberValue}
        onChange={(val) => {
          setNumberValue(val)
        }}
      /> }

      {/* Value input = boolean */}
      {(variable && changeValue && type === 'boolean' || (!variable && type === 'boolean')) && <DropdownSelect
        label={'Value'}
        options={[
          {value: true, display: 'True'},
          {value: false, display: 'False'},
        ]}
        className={'margin-top-20'}
        width={374}
        // height={32}
        value={booleanValue}
        onChange={(val) => {
          setBooleanValue(val)
        }}
      />}
      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title='Variable'
      contents={[content]}
      yesButtonText={yesButtonText}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          let variableValue: any = numberValue
          if (type === 'boolean') {
            variableValue = booleanValue
          }

          // UPDATE CODEPATH
          if (variable) {
            const variables = user.variables
            const oldVarData = variables[variable.symbol]
            delete variables[variable.symbol]
            variables[name] = oldVarData
            variables[name].symbol = name
            variables[name].type = changeValue ? type : oldVarData.type
            variables[name].value = changeValue ? variableValue : oldVarData.value

            if (changeValue) {
              variables[name].last_set = firebase.firestore.FieldValue.serverTimestamp()
            }
            await db.collection('users').doc(user.uid).update({variables})

            // CREATE NEW CODEPATH
          } else {
            const newVariableObject = user.variables || {}
            let highestIndex = 0;
            (Object.keys(user.variables)).forEach(v => {
              const variable = user.variables[v]
              if (variable.index > highestIndex) {
                highestIndex = variable.index
              }
            })
            debugger
            newVariableObject[name] = {
              symbol: name,
              type: type,
              value: variableValue,
              last_set: firebase.firestore.FieldValue.serverTimestamp(),
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
              index: highestIndex + 1
            }
            await db.collection('users').doc(user.uid).update({variables: newVariableObject})
          }

          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

