import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { TickerModal } from '../modals/TickerModal'
import { Backtest } from '../../types/backtest_types'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay'
const config = getConfig() as any

interface BacktestFunctionsTabProps {
  backtest: Backtest | null
}
export const BacktestFunctionsTab = (props: BacktestFunctionsTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const { backtest } = props
  const functions = backtest?.functions || []

  if (!user || !uld || !backtest) return null

  console.log(backtest)
  return (
    <div className='tab-contents has-subtab'>
      {functions?.map((functionObj, i) => {
        return <div className='generic-content-container'>
          <div style={{marginBottom: 7, fontWeight: 'bold'}}>{'Function: ' + functionObj.name} • {functionObj.triggerInterval}</div>
          <pre style={{margin: 0}}><code>
            {functionObj.codeBlock.length ? functionObj.codeBlock : '-'}
          </code></pre>
        </div>          
      })}
    </div>
  )
}