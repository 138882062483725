import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { TickerModal } from '../modals/TickerModal'
import { Backtest } from '../../types/backtest_types'
const config = getConfig() as any

interface BacktestSummaryTabProps {
  backtest?: Backtest | null
}
export const BacktestSummaryTab = (props: BacktestSummaryTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  if (!user || !uld) return null

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData

  return (
    <div className='tab-contents has-subtab'>
      <div className='generic-content-container'>
        <div className='generic-title'>Overview</div>
        <div className='generic-content-column'>
          <div className='generic-content-item'>
            <div>cash</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.cash)}</div>
          </div>
          <div className='generic-content-item'>
            <div>mkt_value</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.mkt_value)}</div>
          </div>
          <div className='generic-content-item'>
            <div>equity</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.equity)}</div>
          </div>
        </div>
      </div>
      <div className='generic-content-container'>
        <div className='generic-title'>Return</div>
        <div className='generic-content-column'>
          <div className='generic-content-item'>
            <div>unrealized_pl</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.unrealized_pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>realized_pl</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.realized_pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>pl</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.pl)}</div>
          </div>
        </div>
      </div>
      <div className='generic-content-container'>
        <div className='generic-title'>Misc</div>
        <div className='generic-content-column'>
          <div className='generic-content-item'>
            <div>Orders</div>
            <div>{formatNumberForTable(props.backtest ? props.backtest?.orders.length : 0).split('.')[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>Transfers</div>
            <div>{formatNumberForTable(props.backtest ? props.backtest?.transfers.length : 0).split('.')[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>Ticks processed</div>
            <div>{formatNumberForTable(props.backtest ? props.backtest?.ticks_processed : 0).split('.')[0]}</div>
          </div>
        </div>
      </div>
    </div>
  )
}