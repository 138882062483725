

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { backtestsAtom, deploymentsAtom, loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { currentUser, db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
import axios from 'axios'
import { getConfig } from '../../config'
const config = getConfig() as any

export const TakeDownDeploymentModal = () => {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [deployments, ] = useAtom(deploymentsAtom)


  if (!user) return null

  const liveDeployment = deployments.find(d => d.id === user.liveDeploymentId)

  return (
    <Modal
      title={'Take down live deployment'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to take down the live deployment: '{liveDeployment?.name}'?
            <br/>
            <br/>
            This operation cannot be undone.
          </div>
        </div>
      ]}
      yesButtonText={'Take down'}
      isDanger
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)                 
          await db.collection('users').doc(user.uid).update({liveDeploymentId: ''})          
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

