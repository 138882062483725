import '../reusable/Modal.scss'
import { useAtom } from 'jotai'
import React, { useEffect, useState, useRef } from 'react'
import { Input } from '../reusable/Input'
import { Icon, Modal } from '../reusable'
import { loggedInUserAtom, userLiveDocAtom } from '../../types/global_types'
import axios from 'axios'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import {
  topStockChartsObjArr,
  updateLiveData,
  ORANGE,
  uuid
} from '../../logic/u'
import { allSymbolsAtom } from '../../types/global_types'
import { WatchlistObj } from '../../types/user_types'
const config = getConfig() as any

interface AddTickersModalProps {
  watchlistId: WatchlistObj['id']
}

export const AddTickersModal = (props: AddTickersModalProps) => {
  // General helpers
  const [user, ] = useAtom(loggedInUserAtom)
  const [uld, ] = useAtom(userLiveDocAtom)
  const [allSymbols] = useAtom(allSymbolsAtom)
  const [searchString, setSearchString] = useState<string>('')
  const [col1Selection, setCol1Selection] = useState<string>('all')
  const [err_msg, setErr_msg] = useState<string>('')
  const inputRef = useRef(null)

  const {watchlistId } = props

  const topStockCharts = topStockChartsObjArr

  if (!user) return null
  
  const all_options = {
    all: topStockCharts,
  }
  
  user.watchlists.forEach(wObj => {
    all_options[wObj.name] = user?.watchlists.find(w => w.id === wObj.id)?.tickers?.map(t => {
      return {
        symbol: t,
        name: allSymbols?.find(s => s.symbol === t)?.name
      }
    })
  })

  const make_first_col_buckets = () => {
    let ret: any = []
    Object.keys(all_options).forEach((option: string) => {
      let className = 'bucket'
      if (col1Selection === option) className += ' s'
      ret.push(
        <div
          key={option}
          className={className}
          onClick={() => {
            // @ts-ignore
            inputRef.current?.clear()
            setErr_msg('')
            setCol1Selection(option)
            setSearchString('')
          }}
        >
          <div className='add-ellipses-cutoff' style={{maxWidth: '90%'}}>{option}</div>
          {option === user.watchlists.find(w => w.id === watchlistId)?.name ? <div className='orange-dot' /> : null}
        </div>
      )
    })
    return ret
  }

  const make_second_col_buckets = () => {
    let ret: any[] = []

    // Assemble lists we'll have if there's no search term
    let options: any[] = all_options[col1Selection]

    // If we do have a search term, we filter by that
    if (searchString.length) {
      options = filterOptions(allSymbols, searchString)
    }

    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      ret.push(
        <div 
          className={'bucket'} 
          key={uuid()}
          onClick={ async () => {
            const tickers = user.watchlists.find(w => w.id === watchlistId)?.tickers || []
            const ticker = option.symbol
            if (tickers.includes(ticker)) {
              const updatedTickers = tickers.filter(t => t !== ticker)
              let charts = user.charts
              delete charts[ticker]
              await db.collection('users').doc(user.uid).update({
                watchlists: user.watchlists.map(w =>
                  w.id === watchlistId ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
                ),
                charts
              })
            } else {
              const updatedTickers = [...tickers, ticker]
              await db.collection('users').doc(user.uid).update({
                watchlists: (user.watchlists || []).map(w =>
                  w.id === watchlistId ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
                ),
                charts: {
                  ...user.charts,
                  [ticker]: {
                    symbol: ticker,
                    display: option.name,
                    annotations: {},
                    indicators: {},
                  }
                }
              })
            }
            await updateLiveData(user)
          }}
        >
          <div className='row'>
            <Icon
              icon={'square-' + option.symbol.charAt(0)}
              set='sharp-solid'
              size={15}
              className={'key-icon'}
              style={{ marginTop: -1, marginRight: 10 }}
            />
            <div className='indicators-modal-val smaller'>{option.symbol}</div>
            <div className='indicators-modal-display'>{option.name}</div>
          </div>
          {(user.watchlists || []).find(w => w.id === watchlistId)?.tickers.includes(option.symbol) ? <div title={`${option.symbol} is in ${user.watchlists.find(w => w.id === watchlistId)?.name}`} className='orange-dot' /> : null}
        </div>
      )
    }
    return ret.length ? ret : <div className='no-results'>No tickers.</div>
  }

  const filterOptions = (options: any[], searchString: string) => {
    const ticker_matches = options.filter(option => {
      return option.symbol.toLowerCase().startsWith(searchString.toLowerCase())
    })

    // Name matches too
    const name_matches = options.filter(option => {
      return option.name.toLowerCase().startsWith(searchString.toLowerCase())
    })

    let all_matches = [...ticker_matches, ...name_matches]
    return all_matches.slice(0, 50)
  }

  return (
    <Modal
      title={'TICKERS > ' + user.watchlists.find(w => w.id === watchlistId)?.name}
      contents={[(
        <div className='indicators-modal-container'>
          <Input
            noLabel={true}
            placeholder={'Search'}
            value={searchString}
            ref={inputRef}
            onChange={(val) => {
              setErr_msg('')
              setSearchString(val)
              setCol1Selection('')
              if (val === '') {
                setCol1Selection('all')
              }
            }}
          />
          <div className='col-container'>
            <div className='first-col'>
              {make_first_col_buckets()}
            </div>
            <div className='second-col-conatiner'>              
              <div className='second-col'>
                {make_second_col_buckets()}
              </div>
              {err_msg ? <div className='err-message'>{err_msg}</div> : null}
            </div>
          </div>
        </div>
      )]}
      style={{width: 800, marginLeft: -200}}
    />
  )
}

