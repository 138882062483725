import './EvaluationsBit.scss'
import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { EELog } from '../../types/user_types'
import { formatDateForTable, format_price, timeAgo, valueForTable } from '../../logic/u'
import { loggedInUserAtom, openModalAtom, userLiveDocAtom } from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import { EvaluationRow } from '../reusable/EvaluationRow'
import { ORANGE, ORANGE_FAINT } from '../../logic/colors'
import { LogModal } from '../modals/LogModal'

const LOAD_INCREMENT = 50

export const EvaluationsBit = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [logs, setLogs] = useState<EELog[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const [haveMore, setHaveMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  // Initially load cached logs
  useEffect(() => {
    if (uld?.cachedEELogs) {
      const sortedLogs = [...uld.cachedEELogs].sort((a, b) => new Date(b.time_iso).getTime() - new Date(a.time_iso).getTime());
      setLogs(sortedLogs);
    } else {
      setLogs([]);
    }
  }, [uld]);

  // Load more if user's scrolled with 200px of the bottom
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = async () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < 200) {
        if (!haveMore || loadingMore) return
        console.log(`past check, loading more. haveMore: ${haveMore}, loadingMore: ${loadingMore}`)
        await load_more()
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  // Used for incremental load after we've exhausted the cache
  const load_more = async () => {
    setLoadingMore(true);

    try {
      await new Promise<void>((resolve) => {
        setLogs((currentLogs) => {

          // No logs to load more case
          if (currentLogs.length === 0) {
            setLoadingMore(false);
            resolve();
            return currentLogs;
          }

          const last_log = currentLogs[currentLogs.length - 1];

          // No last log case
          if (!last_log) {
            setLoadingMore(false);
            resolve();
            return currentLogs;
          }

          // Load previous chunk from firebase
          const beforeTime = new Date(last_log.time_iso);
          const query = db
            .collection('users')
            .doc(user?.uid)
            .collection('EELogs')
            .where('time', '<', beforeTime)
            .orderBy('time', 'desc')
            .limit(LOAD_INCREMENT);

          query.get().then((snapshot) => {
            const newLogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // If none, we're done
            if (newLogs.length === 0) {
              setHaveMore(false);
              setLoadingMore(false);
              resolve();
              return currentLogs;
            }

            // Otherwise append to current logs. If less than LOAD_INCREMENT, we're done
            setHaveMore(newLogs.length >= LOAD_INCREMENT);
            setLoadingMore(false);
            resolve();
            return [...currentLogs, ...newLogs];
          });

          return currentLogs; // Return current logs while waiting for the query
        });
      });
    } catch (error) {
      console.error('Error loading more logs:', error);
      setLoadingMore(false);
    }
  };

  if (!user || !uld) return null

  return (
    <div className='evaluations-bit' ref={containerRef}>
      {!logs.length ? (
        <div className='no-evals-text'>
          No logs.
        </div>
      ) : null}

      {logs.map((log: EELog) => {
        if (Object.keys(log).length === 0) return null
        return <EvaluationRow log={log} />
      })}

      {/* Bottom message, if loading or no more present */}
      {!haveMore || loadingMore ? <div
        style={{
          textAlign: 'center',
          padding: 10,
          height: 20,
          color: ORANGE,
          // backgroundColor: ORANGE_FAINT,
          fontSize: 11,
        }}
      >
        {!haveMore ? "You've reached the end" : null}
        {loadingMore ? "Loading more..." : null}
      </div> : null}

    </div>
  )
}