import './Strategies.scss'
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  userLiveDocAtom,
  loggedInUserAtom,
} from '../../types/global_types';
import Split from 'react-split';
import { db } from '../../firebase';
import { CodeConsole } from './CodeConsole';
import { CodeEditorDividerPane } from './CodeEditorDividerPane';
import { StateBox } from '../sidebars/StateBox';

export const StrategiesTab = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)

  useEffect(() => {
    const returnSplitToDefault = () => {
      db.collection('users').doc(user?.uid).update({ strategiesTabDividerSplit: [70, 30] });
    };

    const gutterElement = document.querySelector('.strategies-tab-split .gutter-vertical');

    if (gutterElement) {
      gutterElement.addEventListener('dblclick', returnSplitToDefault);
    }

    return () => {
      if (gutterElement) {
        gutterElement.removeEventListener('dblclick', returnSplitToDefault);
      }
    };
  }, [user]);

  if (!user || !uld) return null

  let strategiesColClass = 'strategies-col'
  if (user.strategiesTreeClosed) {
    strategiesColClass += ' strategies-tree-closed'
  }

  let code_console_visible = user.codeConsoleVisible
  if (user.codeConsoleVisible === undefined) code_console_visible = true

  return (
    <div className={strategiesColClass} >
      <Split
        className={user.codeConsoleVisible ? 'strategies-tab-split' : 'strategies-tab-split no-code-console'}
        direction='vertical'
        sizes={!user.codeConsoleVisible ? [70, 30] : user.strategiesTabDividerSplit || [70, 30]}
        minSize={user.codeConsoleVisible ? 37 : 0}
        gutterSize={user.codeConsoleVisible ? 5 : 0}
        gutterAlign='center'
        cursor='row-resize'
        onDragEnd={(val) => {
          db.collection('users')
                .doc(user.uid)
                .update({strategiesTabDividerSplit: val})
        }}
      >
        <CodeEditorDividerPane />
        {code_console_visible ? <div className='console-box-container'>
          <div className='handle' />
          <CodeConsole /> 
          <StateBox />
        </div> : null}
      </Split>
    </div>
  )
}
