/*
As of 1 May 2024, used exclusively by FunctionsTab
*/

import { useAtom } from 'jotai'
import { loggedInUserAtom, openModalAtom, strategiesWithUnsavedChangesAtom } from '../../types/global_types'
import React, { useState, useEffect, useRef } from 'react'
// import { Couplet } from '../../types/couplet_types'
import { Strategy } from '../../types/user_types'
import { Editor, loader } from '@monaco-editor/react'
import * as monaco from 'monaco-editor'; // Import monaco types
import { TestFunctionModal } from '../modals/TestFunctionModal'
import { RunFunctionOnceModal } from '../modals/RunFunctionOnceModal'

interface CodeEditorProps {
  strategy: Strategy,
}

export const CodeEditor = (props: CodeEditorProps) => {
  const { strategy } = props
  const [editorValue, setEditorValue] = useState<any>(strategy.code)
  const [user, ] = useAtom(loggedInUserAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const containerRef: any = useRef(null) // do we need this
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null) // do we need this
  const viewZoneIdsRef = useRef<string[]>([])
  const [, setOpenModal] = useAtom<any>(openModalAtom)

  useEffect(() => {
    loader.init().then(monaco => {
      monaco.editor.defineTheme('my-theme', {
        base: 'vs-dark',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': '#00000000',
          'minimap.background': '#00000000',
          'minimapSlider.background': '#55555533',
          'minimapSlider.hoverBackground': '#66666633',
          'minimapSlider.activeBackground': '#88888833',
        },
      })

      // Register a completion item provider
      // registerCompletionProvider(monaco, ["TSLA", "NVDA"])

    })

    return () => {
      setEditorValue(null)
    };
  }, [])

  useEffect(() => {
    setEditorValue(strategy.code)
    if (editorRef.current) {
      addViewZonesToIntervalCalls(editorRef.current, strategy.code)
    }
  }, [strategy])

  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setEditorValue(value)
      let newState = { ...strategiesWithUnsavedChanges }
      newState[strategy.id] = value
      setStrategiesWithUnsavedChanges(newState)
    }
  }

  // Function to add View Zones above functions.interval calls
  const addViewZonesToIntervalCalls = (editor: monaco.editor.IStandaloneCodeEditor, code: string) => {
    const model = editor.getModel()
    if (!model) return

    // Clear existing View Zones
    editor.changeViewZones(accessor => {
      viewZoneIdsRef.current.forEach(id => accessor.removeZone(id))
      viewZoneIdsRef.current = []
    })

    // Look for function definitions: `strategy.on_interval(` or `strategy.on_trigger(`
    const intervalRegex = /\bstrategy\.(on_interval|on_trigger)\(\s*/g;
    // const intervalRegex = /^export\s+const\s+\w+\s*=\s*strategy\.(on_interval|on_trigger)\(['"`](\w+)['"`],\s*\(\)\s*=>\s*\{/gm;
    // const intervalRegex = /^const\s+\w+\s*=\s*strategy\.(on_interval|on_trigger)\(['"`](\w+)['"`],\s*\(\)\s*=>\s*\{/gm




    let match: RegExpExecArray | null

    while ((match = intervalRegex.exec(code)) !== null) {
      const duration = match[1]
      const matchIndex = match.index
      const startPosition = model.getPositionAt(matchIndex)
      const lineNumber = startPosition.lineNumber

      // Ensure lineNumber is valid
      if (lineNumber < 1) continue

      // Create a DOM element for the View Zone
      const domNode = document.createElement('div')
      domNode.style.display = 'flex'
      domNode.style.flexDirection = 'row'
      domNode.style.alignItems = 'center'
      domNode.style.justifyContent = 'flex-start'
      domNode.style.cursor = 'pointer'
      domNode.style.height = '30px'
      domNode.style.width = '100%'
      domNode.style.zIndex = '100'

      // Create the "Test function" button
      const testButton = document.createElement('button')
      testButton.className = 'hov-monaco-btn'
      testButton.innerText = "TEST"
      testButton.style.marginRight = '8px'
      testButton.style.fontSize = '10px'
      testButton.style.padding = '0px'
      testButton.style.paddingBottom = '2px'
      testButton.style.backgroundColor = 'transparent'
      testButton.style.color = '#507b95'
      testButton.style.border = 'none'
      testButton.style.borderRadius = '4px'
      // testButton.style.cursor = 'pointer'

      // Prevent click from propagating to the View Zone
      testButton.addEventListener('click', (e) => {
        e.stopPropagation();
        if (editorRef.current) {
          const model = editorRef.current.getModel();
          if (model) {
            const lineText = model.getLineContent(lineNumber);
            const match = lineText.match(/on_(?:interval|trigger)\(\s*'([^']*)'/);
            const function_name = match ? match[1] : ''
            setOpenModal(
              <TestFunctionModal
                strategyObj={strategy}
                function_name={function_name}
              />
            )
            return
          }
        }
        console.log('internal error - instance is not available.');
      })

      const dot = document.createElement('span');
      dot.style.color = '#507b95'
      dot.innerText = '•';

      // Create the "Run once" button
      const runOnceButton = document.createElement('button')
      runOnceButton.innerText = "RUN"
      runOnceButton.className = 'hov-monaco-btn'
      runOnceButton.style.fontSize = '10px'

      runOnceButton.style.padding = '0px'
      runOnceButton.style.paddingBottom = '2px'
      runOnceButton.style.paddingLeft = '10px'
      runOnceButton.style.backgroundColor = 'transparent'
      runOnceButton.style.color = '#507b95'
      runOnceButton.style.border = 'none'
      runOnceButton.style.borderRadius = '4px'
      // runOnceButton.style.cursor = 'pointer'

      // Prevent click from propagating to the View Zone
      runOnceButton.addEventListener('click', (e) => {
        e.stopPropagation();
        if (editorRef.current) {
          const model = editorRef.current.getModel();
          if (model) {
            const lineText = model.getLineContent(lineNumber);
            const match = lineText.match(/on_(?:interval|trigger)\(\s*'([^']*)'/);
            const function_name = match ? match[1] : ''
            setOpenModal(
              <RunFunctionOnceModal
                strategyObj={strategy}
                function_name={function_name}
              />
            )
            return
          }
        }
        console.log('internal error - instance is not available.');
      })

      // Append buttons to the DOM node
      domNode.appendChild(testButton)
      domNode.appendChild(dot)
      domNode.appendChild(runOnceButton)

      // Define the View Zone
      const viewZone: monaco.editor.IViewZone = {
        afterLineNumber: lineNumber - 1, // Insert above the current line
        heightInPx: 18, // Adjust height as needed to accommodate buttons
        domNode: domNode,
        // No support for RTL/LTR, default is LTR
      }

      // Add the View Zone
      editor.changeViewZones(accessor => {
        const zoneId = accessor.addZone(viewZone)
        viewZoneIdsRef.current.push(zoneId) // Store zoneId directly
      })
    }
  }

  if (!user || !strategy) return null

  return (
    <div className={`code-editor-container`} ref={containerRef}>
      <Editor
        height="100%"
        width="100%"
        theme="my-theme"
        // defaultLanguage="javascript"
        language="javascript"
        value={editorValue}
        onChange={handleEditorChange}
        onMount={(editor) => {
          editorRef.current = editor
          addViewZonesToIntervalCalls(editor, strategy.code)

          // Listen to content changes to update View Zones dynamically
          editor.onDidChangeModelContent(() => {
            const currentValue = editor.getValue()
            addViewZonesToIntervalCalls(editor, currentValue)
          })
        }}
        options={{
          fontSize: 11.5,
          readOnly: false,
          // lineNumbersMinChars: 2,
          padding: {
            top: 0
          },
          minimap: {
            enabled: true
          },
          scrollbar: {
            // vertical: 'visible',
            // verticalScrollbarSize: 14,
            // horizontal: 'visible',
          },
          // scrollBeyondLastLine: true,
          // wordWrap: 'on',
        }}
      />
    </div>
  )
}
