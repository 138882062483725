import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import {
  functionExecutingAtom,
  consoleOutputAtom,
  loggedInUserAtom,
  cmdKAtom,
  appTabAtom,
  openModalAtom,
} from '../../types/global_types';
import { Icon } from '../reusable/Icon';
import { Spinner } from '../reusable/Spinner';
import axios from 'axios';

const ANIMATION_LINE_DELAY = 40;

interface CodeConsoleProps {
  fromModal?: boolean
}

export const CodeConsole = (props: CodeConsoleProps) => {
  const [functionExecuting, setFunctionExecuting] = useAtom(functionExecutingAtom);
  const [user] = useAtom(loggedInUserAtom);
  const [consoleOutput] = useAtom(consoleOutputAtom);
  const [visibleOutput, setVisibleOutput] = useState<any>([]);
  const [cmdK] = useAtom(cmdKAtom);
  const containerRef = useRef<any>(null);
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const lastConsoleOutputLengthRef = useRef(0);
  const animationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const {fromModal} = props

  // Cmd + k to clear console
  useEffect(() => {
    if (!cmdK) return;
    setVisibleOutput([]);
  }, [cmdK]);

  // Scroll to bottom when new content is added
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [visibleOutput]);

  // Handle console output changes, including external resets
  useEffect(() => {
    const handleConsoleOutputChange = () => {
      if (consoleOutput.length === 0 && lastConsoleOutputLengthRef.current !== 0) {
        // Console output was reset (possibly from elsewhere)
        setVisibleOutput([]);
        lastConsoleOutputLengthRef.current = 0;
        if (animationTimeoutRef.current) {
          clearTimeout(animationTimeoutRef.current);
        }
      } else if (consoleOutput.length > lastConsoleOutputLengthRef.current) {
        // New lines added
        const newLines = consoleOutput.slice(lastConsoleOutputLengthRef.current);
        const animateNewLines = async () => {
          for (let i = 0; i < newLines.length; i++) {
            animationTimeoutRef.current = setTimeout(() => {
              setVisibleOutput(prev => [...prev, newLines[i]]);
              if (i === newLines.length - 1) {
                lastConsoleOutputLengthRef.current = consoleOutput.length;
              }
            }, i * ANIMATION_LINE_DELAY);
          }
        };
        animateNewLines();
      }
    };

    handleConsoleOutputChange();

    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [consoleOutput]);

  // Clear animation timeouts on unmount
  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  if (!user) return null;

  return (
    <div className={!fromModal ? 'code-console-container' : 'code-console-container modal-version'}>
      <div className={!fromModal ? 'code-console-header' : 'code-console-header modal-version'}>
        <div>Console output</div>
        <div className='row'>
          {functionExecuting && (
            <div
              className='strategies-tab-header-btn'
              style={{marginRight: 10}}
              onClick={() => {
                setFunctionExecuting(false);
                cancelTokenSourceRef.current.cancel('Function execution canceled.');
                // Note: consoleOutput update handled by atom
                // @ts-ignore
                window.functionCanceled = true;
                cancelTokenSourceRef.current = axios.CancelToken.source();
              }}
            >
              <Icon
                icon='x'
                className='strategies-tab-header-icon'
                hoverText='Kill function execution'
                set='sharp-solid'
                size={8}
                style={{marginTop: 1, marginRight: 5}}
                onClick={() => {
                  // for css
                }}
              />
              <div>Kill</div>
            </div>
          )}
          <div
            className='strategies-tab-header-btn'
            onClick={() => {
              setVisibleOutput([]);
              // Note: consoleOutput update handled by atom
            }}
          >
            <Icon
              icon='eraser'
              className='strategies-tab-header-icon'
              hoverText='Clear console'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>Clear console</div>
          </div>
        </div>
      </div>
      <div className={!fromModal ? 'code-console-body' : 'code-console-body modal-version'} ref={containerRef}>
        {visibleOutput.map((x: any, i) => (
          <div key={i}>{x.content}</div>
        ))}
        {visibleOutput.length === 0 && 'Test or run a function to see output here.'}
        {functionExecuting && (
          <div style={{marginTop: 5, marginLeft: 2}}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};