import './LeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyChartAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnChartPaneContextObj, set_open_chart, formatDateForTable, timeAgo, GREEN, get_contextmenu_off } from '../../logic/u'
import { Icon } from '../reusable'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Chart, Variable, WatchlistObj } from '../../types/user_types'
import { AddTickersModal } from '../modals/AddTickersModal'
import { WatchlistSelectorDropdown } from '../dropdowns/WatchlistsSelectorDropdown';
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal';
import { TickerDropdown } from '../dropdowns/TickerDropdown';
import { VariableDropdown } from '../dropdowns/VariableDropdown';

export const TickersPane = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)
  const [tickers, setTickers] = useState<WatchlistObj['tickers']>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const tickerDropdownRef = useRef<HTMLDivElement>(null);
  const [ streamingQuotes ] = useAtom(streamingQuotesAtom)


  // Render tree
  useEffect(() => {
    setTickers(user?.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers || [])
    // debugger
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let tickersClone = Array.from(tickers)

    // Reorder
    const [reorderedItem] = tickersClone.splice(source.index, 1) // remove dragged item
    tickersClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setTickers(tickersClone) // update local state to smooth transition
    // debugger
    db.collection('users').doc(user?.uid).update({ watchlists: user?.watchlists.map(w =>
      w.id === user.selectedWatchlistIdHomeTab ? { ...w, tickers: tickersClone } : w
    )});
  }

  if (!user) return null
  if (!uld || !uld.liveData) return null

  return <>
    {openDropdown}
    <div className='filter-bar'>
      <div
        className={user.watchlists?.length ? 'watchlist-dropdown-container' : 'row'}
        ref={tickerDropdownRef}
        onClick={(e) => {
          if (!user.watchlists?.length) {
            return
          }
          const rect = tickerDropdownRef.current?.getBoundingClientRect();
          if (rect) {
            const clickX = rect.left
            const clickY = rect?.top - rect?.height + 40
            setOpenDropdown(
              <WatchlistSelectorDropdown
                left={clickX}
                top={clickY}
                onClose={() => setOpenDropdown(null)}
              />
            )
          }
        }}
      >
        <div className={'watchlist-dropdown-title'}>
          {user.watchlists?.find(w => w.id === user.selectedWatchlistIdHomeTab)?.name}
        </div>
        {user.watchlists?.length ? <Icon
          icon='chevron-down'
          className='custom-dropdown-icon'
          size={9}
          style={{marginLeft: 5}}
        /> : null}
      </div>
      <Icon
        icon='plus'
        set='sharp-solid'
        size={11}
        style={{
          marginTop: 2
        }}
        onClick={() => {
          if (user.watchlists?.length) {
            setOpenModal(<AddTickersModal watchlistId={user.selectedWatchlistIdHomeTab} />)
            return
          }
          setOpenModal(<CreateWatchlistModal fromHome={true} />)
        }}
      />
    </div>
    <div className='pane-body has-filter-bar'>
      {user.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers.length ? <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tickers.map((t, index) => {
                  if (!Object.keys(user.charts).includes(t)) {
                    return
                  }
                  const ticker: Chart = user.charts[t]
                  const chart = ticker.symbol
                  const chartIcon = 'square-' + ticker.symbol.charAt(0)
                  const chartPaneContextObj = returnChartPaneContextObj(user, chart)
                  const chartIsVisible = chartPaneContextObj?.chartIsVisible
                  let parentClass = 'entry'
                  if (chartIsVisible) {
                    parentClass += ' selected'
                  }
                  return (
                    <Draggable
                      key={ticker.symbol}
                      draggableId={ticker.symbol}
                      index={index}
                    >
                      {(provided, snapshot) => {

                        // Collect and format price change data
                        let price = uld.liveData[ticker.symbol]?.price
                        let day_price_change = uld.liveData[ticker.symbol]?.day_price_change
                        let day_price_change_pc = uld.liveData[ticker.symbol]?.day_price_change_pc
                        if (streamingQuotes[ticker.symbol]) {
                          price = streamingQuotes[ticker.symbol].price
                          day_price_change = streamingQuotes[ticker.symbol].day_price_change
                          day_price_change_pc = streamingQuotes[ticker.symbol].day_price_change_pc
                        }
                        let day_price_change_fmt = valueForTable('day_price_change', day_price_change)
                        let day_price_change_pc_fmt = valueForTable('day_price_change_pc', day_price_change_pc)
                        let tag_string = day_price_change_fmt + ' [' + day_price_change_pc_fmt + ']'

                        return (
                          <div
                            className={parentClass}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={ async (event) => {
                              event.stopPropagation();
                              set_open_chart(user, uld, selectedEmptyChart || 1, chart)
                              return
                            }}
                            onContextMenu={(event) => {
                              if (get_contextmenu_off()) return
                              event.preventDefault()
                              const width = 220
                              const clickX = event.clientX + 5
                              const clickY = event.clientY - 10
                              if (user.selectedWatchlistIdWatchlistsTab) {
                                setOpenDropdown(
                                  <TickerDropdown
                                    symbol={ticker.symbol}
                                    watchlistId={user.selectedWatchlistIdHomeTab}
                                    left={clickX}
                                    top={clickY}
                                    width={width}
                                    onClose={() => setOpenDropdown(null)}
                                  />
                                )
                              }
                            }}
                          >
                            <div className='column'>
                              <div className='top-row'>
                                <div className='row'>
                                  <div className='icon-container'>
                                    <Icon
                                      icon='grip-dots-vertical'
                                      size={5.5}
                                      className='grip-icon'
                                    />
                                  </div>
                                  <Icon
                                    icon={chartIcon}
                                    set='sharp-solid'
                                    size={12}
                                    className={'key-icon'}
                                    style={{marginTop: 2, marginRight: 5, marginLeft: 0}}
                                  />
                                  <div>
                                    {ticker.symbol}
                                  </div>
                                  {Object.keys(uld.liveData.POSITIONS).includes(ticker.symbol) ? <div
                                    className='tag'
                                  >
                                    POS
                                  </div> : null}
                                </div>
                                <div
                                  className={day_price_change >= 0 ? ' green-tag' : ' red-tag'}
                                >
                                  {tag_string}
                                </div>
                              </div>
                              <div className='row space-between width-full font-size-11'>
                                <div>{valueForTable('price', price)}</div>                                 
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext> : <div style={{marginTop: 8, marginLeft: 10, fontSize: 11.5}}>
        No tickers.
      </div>}
    </div>
  </>
}