

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { WatchlistObj } from '../../types/user_types'
import { db } from '../../firebase'

interface DuplicateWatchlistModalProps {
  watchlistId: string
}

export const DuplicateWatchlistModal = (props: DuplicateWatchlistModalProps) => {
  const {watchlistId} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  const watchlistObj = (user?.watchlists || []).find(w => w.id === watchlistId)

  // Form fields
  const [name, setName] = useState<string>(watchlistObj?.name.replace(/\s/g, '_') + '_COPY' || '')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingWatchlists: WatchlistObj[] = []
    conflictingWatchlists = user?.watchlists?.filter(w => w.name === name) || []
    if (conflictingWatchlists?.length) {
      errorMessage = 'Watchlist name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Watchlist name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Watchlist name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name && !errorMessage)
  }, [name, errorMessage]);

  if (!user || !watchlistObj) return null

  const content = (
    <div className='column width-full'>
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_');
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Duplicate'}
      contents={[content]}
      yesButtonText={'Duplicate'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)

          let newWatchlistObj = {...watchlistObj as WatchlistObj}
          newWatchlistObj.id = uuid()
          newWatchlistObj.createdAt = new Date()
          newWatchlistObj.updatedAt = new Date()
          newWatchlistObj.name = name

          let currentWatchlists = user.watchlists || []
          currentWatchlists.push(newWatchlistObj)

          await db.collection('users').doc(user.uid).update({watchlists: currentWatchlists})
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

