import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatDateForTable,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../logic/u'
import { currentUser, db } from '../../firebase'
import {
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { EvaluationRow } from '../reusable/EvaluationRow';
import { ORANGE_FAINT } from '../../logic/colors'
import { set } from 'lodash'
import { CustomGridOverlay } from './CustomGridOverlay'
import { TickerModal } from '../modals/TickerModal'
import { LogModal } from '../modals/LogModal'
import { Backtest } from '../../types/backtest_types'

const LOAD_INCREMENT = 50

interface LogsTabProps {
  backtest?: Backtest | null
}
export const LogsTab = (props: LogsTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [logs, setLogs] = useState<any[] | null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [additionalIncrementsLoaded, setAdditionalIncrementsLoaded] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [, setOpenModal] = useAtom(openModalAtom)

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'time',
      sort: 'desc' as GridSortDirection
    },
  ]);

  useEffect(() => {
    if (!uld) return;

    // If backtest mode, load all log docs from subcollection on backtest
    if (props.backtest) {
      const backtestId = props.backtest.id
      db.collection('users').doc(user?.uid).collection('backtests').doc(backtestId).collection('BacktestLogs').get().then(snapshot => {
        const logs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        const processedLogs = logs.map(log => ({
          ...log,
          // @ts-ignore
          date: new Date(log.time_iso),
          id: log.id || uuid()
        }));
        setLogs(processedLogs)
      })
      return
    }

    const raw_logs = uld.cachedEELogs
    const processedLogs = raw_logs.map(log => ({
      ...log,
      date: new Date(log.time_iso),
      id: log.id || uuid()
    }));

    setLogs(processedLogs)
  }, [uld])

  if (!user || !uld) return null

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const CustomFooter = () => {
    return (
      <div style={{ padding: '8px 10px', textAlign: 'center', backgroundColor: ORANGE_FAINT }}>

        {isLoadingMore ? <div>Loading more logs...</div> : null}

        {/* {!hasMore ? <div>No more logs</div> : null} */}
      </div>
    );
  };


  const fetchMoreLogs = useCallback(async () => {
    if (!logs) return;
    if (!hasMore) return;
    if (isLoadingMore) return;
    if (props.backtest) return

    if (logs.length < LOAD_INCREMENT) {
      setHasMore(false);
      return
    }

    setIsLoadingMore(true)

    const oldest_log = logs[0];
    const oldest_log_time_iso = new Date(oldest_log.time_iso);

    try {
      await new Promise<void>((resolve) => {
        const query = db
          .collection('users')
          .doc(user?.uid)
          .collection('EELogs')
          .where('time', '<', oldest_log_time_iso)
          .orderBy('time', 'desc')
          .limit(LOAD_INCREMENT);

        query.get().then((snapshot) => {
          const newLogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setIsLoadingMore(false);

          // If none, we're done
          if (newLogs.length === 0) {
            setHasMore(false);
            resolve();
            return;
          } else if (newLogs.length < LOAD_INCREMENT) {
            setHasMore(false);
          }

          newLogs.forEach((log: any) => {
            log.date = new Date(log.time_iso)
          })

          setLogs((prevLogs) => [...newLogs, ...prevLogs || []]);
          resolve();
        });
      });
    } catch (err) {
      console.log('Error fetching more logs:', err);
    }
  }, [logs, user]);

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData
  let logRows = logs || []

  return (
    <div className='tab-contents has-subtab'>
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={logRows}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 36}
          columns={[
            {
              field: 'time',
              headerName: 'date',
              width: 175,
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter: (params) => formatDateForTable(params.row.date)
            },
            {
              field: 'message',
              headerName: 'message',
              flex: 1,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortable: false,
              sortComparator: handleMUISorting,
              renderCell: (params) => {
                return <EvaluationRow log={params.row} forTable={true} />
              }
            },
            {
              field: 'equity',
              headerName: 'equity',
              width: 175,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                if (props.backtest) {
                  return valueForTable('equity', params.row.liveData?.BALANCES?.equity)
                }
                return valueForTable('equity', params.row._liveData?.BALANCES?.equity)
              }
            },
            {
              field: 'pl',
              headerName: 'pl',
              width: 200,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                if (props.backtest) {
                  return valueForTable('pl', params.row.liveData?.BALANCES?.pl)
                }
                return valueForTable('pl', params.row._liveData?.BALANCES?.pl)
              }
            },
            {
              field: 'pl_pc',
              headerName: 'pl_pc',
              width: 200,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                if (props.backtest) {
                  return valueForTable('pl_pc', params.row.liveData?.BALANCES?.pl_pc)
                }
                return valueForTable('pl_pc', params.row._liveData?.BALANCES?.pl_pc)
              }
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          onRowsScrollEnd={() => {fetchMoreLogs()}}
          hideFooter={!isLoadingMore}
          components={{
            NoRowsOverlay: () => (
              <CustomGridOverlay text={logRows.length === 0 ? 'No logs.' : 'Loading logs...'} />
            ),
            Footer: CustomFooter,
          }}
          sx={{
            ...tableSX,
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { pt: '8px', pb: '8px'},
          }}

          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onRowClick={(params, event) => {
            console.log(params.row)       // for debugging
            setOpenModal(<LogModal log={params.row} />)
          }}
        />
      </div>
    </div>
  )
}