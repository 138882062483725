import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { CreateStrategyModal } from '../modals/CreateStrategyModal'
import { DuplicateStrategyModal } from '../modals/DuplicateStrategyModal'
import { DeleteStrategyModal } from '../modals/DeleteStrategyModal'
import { Strategy } from '../../types/user_types'
import { TEXT_GRAY } from '../../logic/u'

interface StrategyDropdownProps {
  strategyObj: Strategy
  codeEditorSlot?: number
  flagUnsavedChangesBeforeChangingStrategies?: (strategyId: string, changeSelection: boolean) => void
  left: number
  top: number
  width: number
  onClose: () => void
  fromStrategiesList?: boolean
}

export const StrategyDropdown = (props: StrategyDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)

  const {strategyObj, codeEditorSlot, flagUnsavedChangesBeforeChangingStrategies, left, top, width, onClose, fromStrategiesList} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []

  if (fromStrategiesList) {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='file-check'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>Open</div>
          </div>
        ),
        onClick: () => {
          flagUnsavedChangesBeforeChangingStrategies?.(strategyObj.id, true)
        }
      },
    )
  }
  if (!codeEditorSlot) {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='square-pen'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: 1}}
            />
            <div>Update</div>
          </div>
        ),
        onClick: () => {
          setOpenModal(<CreateStrategyModal strategy={strategyObj} />)
        }
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='copy'
              set='sharp-solid'
              size={10}
              style={{marginRight: 10}}
            />
            <div>Duplicate</div>
          </div>
        ),
        onClick: async () => {
          setOpenModal(<DuplicateStrategyModal strategy={strategyObj} />)
        }
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='trash'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10, marginTop: .5}}
            />
            <div>Delete</div>
          </div>
        ),
        onClick: () => {
          setOpenModal(<DeleteStrategyModal strategy={strategyObj} />)
        }
      },
    )
  }
  
  if (user?.codeEditorSlot1 === strategyObj.id || user?.codeEditorSlot2 === strategyObj.id) {
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='circle-x'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>{user.codeEditorSlot1 === strategyObj.id && user.codeEditorSlot2 === strategyObj.id ? 'Clear editor slots' : 'Clear editor slot'}</div>
          </div>
        ),
        onClick: () => {   
          
          flagUnsavedChangesBeforeChangingStrategies?.(strategyObj.id, false)

          if (codeEditorSlot) {
            if (codeEditorSlot === 1) {
              db.collection('users').doc(user?.uid).update({ codeEditorSlot1: '' });
            }
            if (codeEditorSlot === 2) {
              db.collection('users').doc(user?.uid).update({ codeEditorSlot2: '' });
            }
          }  
          
          // The case of the left pane
          if (user.codeEditorSlot1 === strategyObj.id) {
            db.collection('users').doc(user?.uid).update({ codeEditorSlot1: '' });
          }
          if (user.codeEditorSlot2 === strategyObj.id) {
            db.collection('users').doc(user?.uid).update({ codeEditorSlot2: '' });
          }
        }
      },
    )
  }

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}