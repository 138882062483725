

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { db } from '../../firebase'
import { Strategy } from '../../types/user_types'

interface DeleteStrategyModalProps {
  strategy: Strategy
}

export const DeleteStrategyModal = (props: DeleteStrategyModalProps) => {  
  const {strategy} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  if (!user || !strategy) return null

  return (
    <Modal
      title={'Delete strategy'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            Are you sure you want to delete this strategy? This operation cannot be undone.
          </div>
        </div>
      ]}
      yesButtonText={'Delete'}
      isDanger
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          const userId = user.uid
          const strategyId = strategy.id

          await db.collection('users')
            .doc(userId)
            .collection('strategies')
            .doc(strategyId)
            .delete()
            .then(() => {
              console.log('Strategy document successfully deleted!');
            })
            .catch((error) => {
              setOpenModal(<ErrorModal errorMessage={error}/>)
              console.error('Error deleting strategy document:', error);
            });
                    
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

