/*
Single component view of an EELog, used both in evaluationsBit and in the EELogsTable.

Note that
*/

import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { EELog } from '../../types/user_types'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import {
  formatDateForTable,
  humanDateForFirebaseDate,
  stringToFormattedAmount,
  timeAgo,
  valueForTable,
} from '../../logic/u'
import { Icon } from './Icon'
import { db } from '../../firebase'
import { LogModal } from '../modals/LogModal'

interface EvaluationsRowProps {
  log: EELog
  forTable?: boolean
}
export const EvaluationRow = (props: EvaluationsRowProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  const { log } = props

  if (!user) return null

  const date = new Date(log.time_iso)
  const equity = valueForTable('equity', log._liveData?.BALANCES?.equity)
  const day_equity_change = valueForTable('day_equity_change', log._liveData?.BALANCES?.day_equity_change)
  const day_equity_change_pc = valueForTable('day_equity_change_pc', log._liveData?.BALANCES?.day_equity_change_pc)
  let formatted_stats = `${equity} ${day_equity_change} [${day_equity_change_pc}]`
  if (log._liveData?.BALANCES === undefined) formatted_stats = '-'

  const messageDivs = log.console_output.map((co, i) => {
    if ( !co.content || co.content === undefined || co.content === 'undefined' || co.consoleOnly ) return
    let className = 'co-text'
    if (co.isError) {
      className += ' error'
    }
    return <div key={i} className={className}>{co.content}</div>
  }) || []

  let className = 'log-bit'
  if (props.forTable) {
    className += ' for-table'
  }

  return <div
    className={className}
    onClick={ async () => {
      setOpenModal(<LogModal log={log} />)
      const log_id = log.id
      const log_doc = await db.collection('users')
        .doc(user.uid)
        .collection('EELogs')
        .doc(log_id)
        .get()
      const full_log = log_doc.data()
      console.log(full_log)
    }}
  >
    <div className='column'>
      {messageDivs}
    </div>
    {props.forTable ? null : <div className='row'>
      <div title={'equity • day_equity_change • day_equity_change_pc'}>{formatted_stats}</div>
      <div style={{marginLeft: 6}}>{formatDateForTable(date)}</div>
    </div>}
  </div>
}
