import './MenuBar.scss';

import { useAtom } from 'jotai'
import {
  appTabAtom,
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  consoleOutputAtom,
} from '../../types/global_types'
import { CreateOrderModal } from '../modals/CreateOrderModal'
import { CreateTransferModal } from '../modals/CreateTransferModal'
import { db } from '../../firebase'
import {
  Icon,
} from '../reusable'
import React, { useState, useEffect, useRef } from 'react'
import { TEXT_GRAY, formatDateForTable, isMarketOpen, timeAgo, valueForTable } from '../../logic/u'
import { CancelAllOpenOrdersModal } from '../modals/CancelAllOpenOrdersModal'
import { LayoutDropdown } from '../dropdowns/LayoutDropdown';
import { Backtest } from '../../types/backtest_types';
import { RunCodeModal } from '../modals/RunCodeModal';
import { CloseAllPositionsModal } from '../modals/CloseAllPositionsModal';
import { DeploymentDropdown } from '../dropdowns/DeploymentDropdown';

export const MenuBar = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setConsoleOutput] = useAtom(consoleOutputAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)

  const layoutDropdownRef = useRef<HTMLDivElement>(null);
  const deploymentDropdownRef = useRef<HTMLDivElement>(null);

  if (!user) {return null}
  if (!uld) {return null}

  const balances = uld.liveData?.BALANCES

  const date = uld.liveDataLastUpdated?.toDate()
  const time_ago = timeAgo(date)
  const formatted_date = formatDateForTable(date)

  return (
    <div className={'menu-bar'}>
      {openDropdown}
      <div className={'left'}>
        <Icon
          icon={balances.day_equity_change >= 0 ? 'arrow-trend-up' : 'arrow-trend-down'}
          set='sharp-solid'
          size={20}
          className={'logo-icon ' + (balances.day_equity_change >= 0 ? 'up ' : 'down ') + 'margin-left-3 margin-top-2'}
        />
        <div className={'margin-left-15 font-size-14 white-text'}>
          Tickerbot
        </div>
        <div className='row'>
          <div title={'equity'} className={'margin-left-15 font-size-14 white-text'} >
            {valueForTable('equity', balances?.equity)}
          </div>
          <div title={'day_equity_change'} className={'margin-left-15 font-size-14' + (balances?.day_equity_change >= 0 ? ' pos' : ' neg')}>
            {valueForTable('day_equity_change', balances.day_equity_change)}
          </div>
          <div title={'day_equity_change_pc'} className={'margin-left-15 font-size-14' + (balances?.day_equity_change >= 0 ? ' pos' : ' neg')}>
            {'[' + valueForTable('day_equity_change_pc', balances.day_equity_change_pc) + ']'}
          </div>
          <div className='as-of'>
            as of {formatted_date} • {isMarketOpen() ? 'market open' : 'market closed'}
          </div>
        </div>
      </div>
      <div className={'right'}>
        <div
          ref={layoutDropdownRef}
          onClick={(e) => {
            if (layoutDropdownRef.current) {
              const rect = layoutDropdownRef.current.getBoundingClientRect(); // Get the position of the element
              setOpenDropdown(
                <LayoutDropdown
                  left={rect.left - 95}  // Use the position of the element
                  onClose={() => setOpenDropdown(null)}
                />
              );
            }
          }}
        >
          <Icon
            hoverText='Modify layout'
            icon='grid-2-plus'
            set='sharp-solid'
            size={18}
            style={{marginRight: 15, marginTop: 1}}
            onClick={() => {
              // for effect
            }}
          />
        </div>
        <div className='gray-text' style={{marginRight: 15}}>
          |
        </div>
        <Icon
          hoverText='Transfer'
          icon='building-columns'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15}}
          onClick={() => {
            setOpenModal(<CreateTransferModal />)
          }}
        />
        <Icon
          hoverText='Trade'
          icon='right-left'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15}}
          onClick={() => {
            setOpenModal(<CreateOrderModal />)
          }}
        />
        <Icon
          hoverText='Close all positions'
          icon='folder-closed'
          set='sharp-solid'
          size={19}
          style={{marginRight: 15, marginTop: 1}}
          onClick={() => {
            setOpenModal(<CloseAllPositionsModal />)
          }}
        />
        <Icon
          hoverText='Cancel all open orders'
          icon='octagon-minus'
          set='sharp-solid'
          size={18}
          style={{marginRight: 15}}
          onClick={() => {
            setOpenModal(<CancelAllOpenOrdersModal />)
          }}
        />
        <Icon
          hoverText='Execute code'
          icon='rectangle-code'
          set='sharp-solid'
          size={19}
          style={{marginTop: .5, marginRight: 15}}
          onClick={() => {
            setConsoleOutput([])            // single-console-output
            setOpenModal(<RunCodeModal />)
          }}
        />
        <div className='gray-text' style={{marginRight: 15}}>
          |
        </div>
        <div
          ref={deploymentDropdownRef}
          className={`status-indicator`}
          onClick={(e) => {
            if (deploymentDropdownRef.current) {
              const rect = deploymentDropdownRef.current.getBoundingClientRect(); // Get the position of the element
              setOpenDropdown(
                <DeploymentDropdown
                  left={rect.left - 210}  // Use the position of the element
                  onClose={() => setOpenDropdown(null)}
                />
              );
            }
          }}
        >          
          <Icon
            // icon='square-code'
            icon='square-bolt'
            set='sharp-solid'
            className='status-icon'
            size={13}
            style={{marginTop: 0, marginLeft: 0, marginRight: 7}}
          />
          <div>
            Deploy
          </div>
          <Icon
            icon='chevron-down'
            set='sharp-solid'
            className='status-icon'
            size={9}
            style={{marginTop: -7, marginLeft: 6}}
          />
        </div>
      </div>
    </div>
  )
}