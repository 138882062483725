

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { consoleOutputAtom, deploymentsAtom, functionExecutingAtom, loggedInUserAtom, openModalAtom, strategiesAtom, userLiveDocAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { db } from '../../firebase'
import { run_function_once } from '../../logic/run_function_once'
import { Deployment, Strategy } from '../../types/user_types'

interface DeploymentModalProps {
  strategiesToDeploy: {[key: Strategy['id']]: boolean}
}

export const DeploymentModal = (props: DeploymentModalProps) => {
  const {strategiesToDeploy} = props
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [strategies, ] = useAtom(strategiesAtom)
  const [deployments, ] = useAtom(deploymentsAtom)
  const [name, setName] = useState<string>('')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    
    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Deployment name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Deployment name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name && !errorMessage)
  }, [name, errorMessage]);

  if (!user) return null

  const content = (
    <div className='column width-full'>

      {/* Name input */}
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_').toLowerCase().trim();
          modified = modified.slice(0, 50);
          setName(modified);
        }}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Create deployment'}
      contents={[content]}
      yesButtonText={'Create'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)

          // New codepath
          let highestIndex = 0
          deployments.forEach(s => {
            if (s.index > highestIndex) {
              highestIndex = s.index
            }
          })

          const liveCodeToDeploy = {}
          Object.keys(strategiesToDeploy).forEach(s => {
            liveCodeToDeploy[s] = strategies.find(strat => strat.id === s)?.code
          })
          const newDeployment: Omit<Deployment, 'id'> = { // omit 'id' for now
            userId: user.uid,
            createdAt: new Date(),
            index: highestIndex + 1,
            name: name,
            liveCode: liveCodeToDeploy
          }

          
          const newDeploymentRef = await db.collection('users')
            .doc(user.uid)
            .collection('deployments')
            .add(newDeployment)              

          const deploymentId = newDeploymentRef.id

          // Now update the strategy object with the generated id
          const deploymentWithId: Deployment = {
            ...newDeployment,
            id: deploymentId,
          }            
          await db.collection('users')
            .doc(user.uid)
            .collection('deployments')
            .doc(deploymentId)
            .set(deploymentWithId) 

          // Update user's current deployment
          await db.collection('users')
            .doc(user.uid)
            .update({ liveDeploymentId: deploymentId })

          updateLiveData(user)
          setButtonIsLoading(false)
        } catch (err) {
          setButtonIsLoading(true)
          setOpenModal(<ErrorModal errorMessage={err}/>)
        }                                      
      }}
    />
  )
}

