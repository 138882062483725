import {
  Icon,
} from '../reusable'
import { logout } from '../../firebase'
import React, { useState } from 'react'
import { DocumentsTab } from './DocumentsTab'
import { SettingsTab } from './SettingsTab'
import { SubscriptionTab } from './SubscriptionTab'
import { DisclosuresTab } from './DisclosuresTab'

const ACCOUNT_SUBTABS = [
  {display: 'Settings', value: 'settings'},
  {display: 'Subscription', value: 'subscription'},
  {display: 'Documents', value: 'documents'},
  {display: 'Disclosures', value: 'disclosures'}
]

export function AccountTab() {
  const [ subTab, setSubTab ] = useState('settings')

  let subTabContent: any = null
  switch (subTab) {
    case 'settings':
      subTabContent = <SettingsTab />
      break
    case 'subscription':
      subTabContent = <SubscriptionTab />
      break
    case 'documents':
      subTabContent = <DocumentsTab />
      break
    case 'disclosures':
      subTabContent = <DisclosuresTab />
      break
    
  }

  return (
    <div className='tab-parent'>
      <div className='tab-header'>
        <div>
          Account
        </div>        
      </div>
      <div className='subtab-header'>
        {ACCOUNT_SUBTABS.map((s) => {
          let className = 'subtab'
          if (s.value === subTab) {
            className += ' selected'
          }
          return (
            <div
              className={className}
              onClick={() => setSubTab(s.value)}
            >
              {s.display}
            </div>
          )
        })}
      </div>
      {subTabContent}
    </div>
  )
}
