import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatDateForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  deploymentsAtom,
  loggedInUserAtom,
  openModalAtom,
  strategiesAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CreateVariableModal } from '../modals/CreateVariableModal'
import { VariableDropdown } from '../dropdowns/VariableDropdown'
import { CustomGridOverlay } from './CustomGridOverlay'
import { Backtest } from '../../types/backtest_types'

export const DeploymentsTab = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'last_set',
      sort: 'desc' as GridSortDirection
    },
  ]);
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [ strategies, ] = useAtom(strategiesAtom)
  const [ deployments, ] = useAtom(deploymentsAtom)


  if (!user || !uld) return null

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    if (get_contextmenu_off()) return
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    setOpenDropdown(
      <VariableDropdown
        variableObj={params.row}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
      />
    )
  }

  const deploymentRows = deployments || []

  return (
    <div className='tab-contents has-subtab'>
      {openDropdown}
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={deploymentRows}
          // getRowHeight={() => 'auto'}
          // getEstimatedRowHeight={() => 36}
          columns={[
            {
              field: 'id',
              headerName: 'id',
              // flex: .5,
              width: 175,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.id}
                </div>
              )
            },
            {
              field: 'name',
              headerName: 'name',
              flex: .5,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.name}
                </div>
              )
            },
            {
              field: 'strategies',
              headerName: 'strategies',
              flex: 1,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => {
                const strategyIds = Object.keys(params.row.liveCode)
                const strategyStr = strategyIds.map(id => {
                  return strategies.find(s => s.id === id)?.name || `[deleted]`
                })
                return <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {strategyStr.sort().join(', ')}
                </div>
              }
            },
            {
              field: 'createdAt',
              headerName: 'created_at',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.createdAt.toDate())}
                </div>
              )
            },
            
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: () => (
              <CustomGridOverlay text={deploymentRows.length === 0 ? 'No deployments.' : 'Loading deployments...'} />
            ),
          }}
          sx={{
            ...tableSX,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total deployments:',
          }}
          onRowClick={(params) => setOpenModal(<CreateVariableModal variable={params.row} />)}
        />
      </div>

    </div>
  )
}


