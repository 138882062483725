import { useAtom } from 'jotai'
import React, { useRef, useState } from 'react'
import { Modal } from '../reusable'
import {
  openModalAtom,
  userLiveDocAtom,
  loggedInUserAtom,
  consoleOutputAtom,
  functionExecutingAtom,
  strategiesWithUnsavedChangesAtom,
} from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { Strategy } from '../../types/user_types'
import { run_function_once } from '../../logic/run_function_once'
import axios from 'axios'

interface RunFunctionOnceModalProps {
  strategyObj: Strategy
  function_name: string
}

export const RunFunctionOnceModal = (props: RunFunctionOnceModalProps) => {
  const { strategyObj, function_name } = props

  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ consoleOutput, setConsoleOutput ] = useAtom(consoleOutputAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ , setFunctionExecuting ] = useAtom(functionExecutingAtom)
  const [strategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const cancelTokenSource = useRef(axios.CancelToken.source());

  if (!user || !uld || !strategyObj) return null

  return (
    <Modal
      title={`Run ${function_name} once`}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          This operation will run your function once in production mode. It may result in changes to your portfolio.
          <br />
          <br />
          Do you want to proceed?
          {strategiesWithUnsavedChanges[strategyObj.id]?.unsavedEditorValue ? <>
            <br />
            <br />
            <div style={{fontSize: 11, color: 'gray', fontStyle: 'italic'}}>
              Note: Unsaved changes will not be included.
            </div>
          </> : null}
        </div>
      ]}
      yesButtonText={'Run once'}
      isDanger
      // isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        setOpenModal(null)
        try {
          await run_function_once({
            user,
            code: strategyObj.code,
            liveData: uld.liveData,
            consoleOutput,
            setConsoleOutput,
            setFunctionExecuting,
            strategy_id: strategyObj.id,
            function_name: function_name,
            simulated_mode: false,
            cancelToken: cancelTokenSource.current.token
          })
        } catch (error) {
          if (!axios.isCancel(error)) {
            setOpenModal(<ErrorModal errorMessage={error.message} />)
          }
        }
      }}
    />
  )
}
